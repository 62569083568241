import { Button, usePermission } from 'portal-commons';
import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';

export default function CNPDetails(props) {
  const { campaign, nominationInfo, electedDcas } = props;
  const [showElectBtn, setShowElectBtn] = useState(false);
  const { hasPermission } = usePermission();

  useEffect(() => {
    if (campaign.status === 'EXPIRED') {
      setShowElectBtn(false);
    } else if (nominationInfo.status) {
      if (
        ['DECLINED'].includes(nominationInfo.status) &&
        hasPermission('campaignDetail.electCnp')
      ) {
        setShowElectBtn(true);
      } else {
        setShowElectBtn(false);
      }
    } else if (hasPermission('campaignDetail.electCnp')) {
      setShowElectBtn(true);
    }
  }, [campaign.status, nominationInfo.status]);

  return (
    <div className="cnp-dca-details" data-testid="cnpDcaDetails">
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={6}>
          <div
            className="cnp-details-wrapper"
            data-testid="cnpDcaDetailsConnectibityPartner"
          >
            <Grid item xs={4}>
              <p className="paragraph">Connectivity Partner:</p>
            </Grid>
            <Grid container xs={8} className="flex-centered">
              <Grid item xs={4} style={{ minWidth: '150px' }}>
                <p className="paragraph">
                  <span>
                    {nominationInfo.status === 'DECLINED'
                      ? '--'
                      : nominationInfo.upstreamCnpName
                      ? nominationInfo.upstreamCnpName
                      : nominationInfo.upstreamCnpUid
                      ? nominationInfo.upstreamCnpUid
                      : 'NONE'}
                  </span>
                </p>
              </Grid>
              <Grid item xs={7}>
                {showElectBtn ? (
                  <Button
                    className="elect-cnp-btn"
                    color="secondary"
                    onClick={() => props.handleClickCnpAction(true)}
                  >
                    <img
                      src={
                        require('../../../../assets/images/elect-cnp-icon.svg')
                          .default
                      }
                      className="icon"
                    />
                    <span>Elect CNP</span>
                    <NotificationsNoneIcon className="alert-icon" />
                  </Button>
                ) : null}
              </Grid>
            </Grid>
          </div>
        </Grid>
        <Grid item xs={6}>
          <Grid container>
            <Grid item xs={12}>
              <div
                className={`dca-election-info ${
                  electedDcas.length ? 'completed' : 'pending'
                }`}
                data-testid="cnpDcaDetailsElectionInfo"
              >
                {electedDcas.length ? (
                  <React.Fragment>
                    <img
                      src={
                        require(`../../../../assets/images/dca-election-completed-icon.svg`)
                          .default
                      }
                    />
                    <span>DCA Election Completed</span>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <img
                      src={
                        require(`../../../../assets/images/dca-election-pending-icon.svg`)
                          .default
                      }
                    />
                    <span>DCA Election Pending</span>
                  </React.Fragment>
                )}
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
