import React, { FC, useEffect, useState } from 'react';
import { Dialog, DialogContent, Grid, TextField } from '@material-ui/core';
import { BrandErrorCode } from '../../../index';
import {
  Country,
  formatPhoneNumberIntl,
  getCountryCallingCode,
  isValidPhoneNumber,
  Value as PhoneNumberInputValue,
} from 'react-phone-number-input';

import EditBrandCompanyDetails from '../EditBrandCompanyDetails';
import BrandTrustScore from '../BrandTrustScore';
import ToolTip from '../../../../../ui_elements/ToolTip';
import { isValidInput } from '../../../../../utils';
import { SelectItem } from '../../../../../utils/types';
import { fieldValidation } from '../../../../../utils/validator';
import { globalGetService } from '../../../../../utils/globalApiServices';
import { BrandDetail, EditModal } from '../../types';

import { BoxV2 as Box, Flex } from 'portal-commons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBuildingCircleCheck,
  faChartNetwork,
  faTag,
} from '@fortawesome/pro-regular-svg-icons';
import { EntityTypes } from '../../../../../shared_elements/enums';
import { isValidForIdentityStatusChange } from '../../utils';

interface Props {
  open: boolean;
  type: EditModal;
  brandInfo: BrandDetail;
  stockExchanges: SelectItem[];
  verticalTypes: SelectItem[];
  countries: SelectItem[];
  usStates: SelectItem[];
  loader: boolean;
  handleClose: () => void;
  handleSubmitEdit: (data: Partial<BrandDetail>) => void;
  onTypeChange?: (type: EditModal) => void;
}
interface ValidationRule {
  entityType: string;
  attributeName: string;
  country: string;
  regulation: string;
  structure: string;
  example: string;
  regex: string;
  referenceLink: string;
}

const BrandEditModal: React.FC<Props> = ({
  open,
  type,
  brandInfo,
  stockExchanges,
  verticalTypes,
  countries,
  usStates,
  loader,
  handleClose,
  handleSubmitEdit,
  onTypeChange,
}) => {
  type KeyOfBrand = keyof BrandDetail;
  const [brand, setBrand] = useState<BrandDetail>(brandInfo);
  const [error, setError] = useState<Record<string, string>>({});
  const [countryIsoCode, setCountryIsoCode] = useState<Country | undefined>();
  const [validationRules, setValidationRules] = useState<ValidationRule[]>([]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const errorCode: Record<string, any> = BrandErrorCode;

  const handleChange = <K extends KeyOfBrand>(
    value: BrandDetail[K],
    key: K
  ) => {
    //   if(key === "website"){
    //         value = value.toLowerCase()
    //   }
    if (key === 'country' && value !== brand.country) {
      setBrand(
        (prevBrand) =>
          ({
            ...prevBrand,
            state: '',
            street: '',
            city: '',
            postalCode: '',
            ein: '',
            einIssuingCountry:
              brand.entityType === 'SOLE_PROPRIETOR' ? '' : value,
            [key]: value,
          } as BrandDetail)
      );
      setError((prevError) => ({
        ...prevError,
        state: '',
        street: '',
        city: '',
        postalCode: '',
        ein: '',
        einIssuingCountry: '',
        [key]: '',
      }));
    } else if (
      key === 'einIssuingCountry' &&
      value !== brand.einIssuingCountry
    ) {
      setBrand(
        (prevBrand) =>
          ({
            ...prevBrand,
            [key]: value,
          } as BrandDetail)
      );
      setError((prevError) => ({
        ...prevError,
        ein: '',
        [key]: '',
      }));
    } else if (key === 'entityType' && value !== brand.entityType) {
      setBrand(
        (prevBrand) =>
          ({
            ...prevBrand,
            stockSymbol: '',
            stockExchange: '',
            businessContactEmail:
              value === EntityTypes.PubliclyTradedCompany
                ? prevBrand.businessContactEmail || ''
                : '',
            [key]: value,
          } as BrandDetail)
      );
      setError((prevError) => ({
        ...prevError,
        stockSymbol: '',
        stockExchange: '',
        [key]: '',
      }));
    } else {
      setBrand(
        (prevBrand) =>
          ({
            ...prevBrand,
            [key]: value ?? '',
          } as BrandDetail)
      );
      setError((prevError) => ({
        ...prevError,
        [key]: '',
      }));
    }
  };

  const handleError = (value: string, key: string) => {
    if (key === 'postalCode' && brand.country !== 'US') {
      setError((prevError) => ({
        ...prevError,
        postalCode: value.length
          ? value.length > 20
            ? 'Maximum 20 chars allowed for'
            : ''
          : 'Please enter ',
      }));
    } else if (key === 'phone') {
      const isValidPhone = isValidPhoneNumber(value);
      setError((prevError) => ({
        ...prevError,
        [key]:
          value.length === 0
            ? 'Please enter Support Phone Number'
            : isValidPhone
            ? ''
            : 'Please enter valid phone number',
      }));
    } else if (key === 'ein') {
      setError((prevError) => ({
        ...prevError,
        [key]: validationVerification(
          value,
          key,
          brand.einIssuingCountry === 'US' ? 'EIN' : 'Tax Number/ID'
        ),
      }));
    } else if (key === 'referenceId') {
      if (brand.entityType === 'SOLE_PROPRIETOR') {
        setError((prevError) => ({
          ...prevError,
          [key]:
            errorCode[key][
              fieldValidation({ ...errorCode[`${key}Obj`], fieldval: value })
            ],
        }));
      } else {
        setError((prevError) => ({
          ...prevError,
          [key]:
            errorCode[key][
              fieldValidation({
                ...errorCode[`${key}Obj`],
                requiredFlag: false,
                fieldval: value,
              })
            ],
        }));
      }
    } else {
      setError((prevError) => ({
        ...prevError,
        [key]:
          errorCode[key][
            fieldValidation({ ...errorCode[`${key}Obj`], fieldval: value })
          ],
      }));
    }
  };

  const validationVerification = (value: string, key: string, label = '') => {
    if (value && value.trim()) {
      if (key === 'ein') {
        if (label === 'EIN') {
          let updatedValue = value.trim();
          updatedValue = updatedValue.replace(
            /[ ^!"#$%&'()*+,-./\\:;<=>?@[\]^_`{|}\s]/gim,
            ''
          );
          const validationObject =
            validationRules &&
            validationRules.find((item) => item.attributeName === 'ein');
          const newRegex =
            validationObject && validationObject.regex
              ? new RegExp(validationObject.regex, 'gmi')
              : /^[0-9]{2}[-.s]?[0-9]{7}$/gim;
          if (newRegex.test(updatedValue)) {
            //
            return '';
          } else {
            return `Invalid ${label} ${
              validationObject && validationObject.structure
                ? `- ${validationObject.structure}`
                : ''
            }`;
          }
        } else {
          return value.length > 21
            ? `Maximum 21 chars allowed for ${label}`
            : ``;
        }
      }
    } else {
      return `Please enter ${label}`;
    }
    return '';
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (type === EditModal.company) {
      const dialCode = countryIsoCode
        ? getCountryCallingCode(countryIsoCode)
        : '';
      const validateNewInput: Record<string, string> = {
        displayName:
          errorCode.displayName[
            fieldValidation({
              ...errorCode.displayNameObj,
              fieldval: brand.displayName,
            })
          ],
        country: brand.country ? '' : 'Please select Country',
        entityType: brand.entityType ? '' : 'Please select your entity type',
        street:
          errorCode.street[
            fieldValidation({
              ...errorCode.streetObj,
              fieldval: brand.street,
            })
          ],
        city: errorCode.city[
          fieldValidation({ ...errorCode.cityObj, fieldval: brand.city })
        ],
        postalCode:
          brand.country === 'US'
            ? errorCode.postalCode[
                fieldValidation({
                  ...errorCode.postalCodeObj,
                  fieldval: brand.postalCode,
                })
              ]
            : brand.postalCode !== ''
            ? brand.postalCode.length > 20
              ? 'Maximum 20 chars allowed for '
              : ''
            : 'Please enter ',
        state:
          errorCode.state[
            fieldValidation({ ...errorCode.stateObj, fieldval: brand.state })
          ],
        stockSymbol:
          brand.entityType === 'PUBLIC_PROFIT'
            ? errorCode.stockSymbol[
                fieldValidation({
                  ...errorCode.stockSymbolObj,
                  fieldval: brand.stockSymbol,
                })
              ]
            : '',
        stockExchange:
          brand.entityType === 'PUBLIC_PROFIT'
            ? errorCode.stockExchange[
                fieldValidation({
                  ...errorCode.stockExchangeObj,
                  fieldval: brand.stockExchange,
                })
              ]
            : '',
        vertical:
          brand.entityType === 'SOLE_PROPRIETOR'
            ? ''
            : errorCode.vertical[
                fieldValidation({
                  ...errorCode.verticalObj,
                  fieldval: brand.vertical,
                })
              ],
        ein:
          brand.entityType === 'SOLE_PROPRIETOR'
            ? ''
            : validationVerification(
                brand.ein ?? '',
                'ein',
                brand.einIssuingCountry === 'US' ? `EIN` : `Tax Number/ID`
              ),
        firstName:
          brand.entityType === 'SOLE_PROPRIETOR'
            ? errorCode.firstName[
                fieldValidation({
                  ...errorCode.firstNameObj,
                  fieldval: brand.firstName,
                })
              ]
            : '',
        lastName:
          brand.entityType === 'SOLE_PROPRIETOR'
            ? errorCode.lastName[
                fieldValidation({
                  ...errorCode.lastNameObj,
                  fieldval: brand.lastName,
                })
              ]
            : '',
        website:
          errorCode.website[
            fieldValidation({
              ...errorCode.websiteObj,
              fieldval: brand.website,
            })
          ],
        mobilePhone:
          brand.entityType === 'SOLE_PROPRIETOR'
            ? errorCode.mobilePhone[
                fieldValidation({
                  ...errorCode.mobilePhoneObj,
                  fieldval: brand.mobilePhone,
                })
              ]
            : '',
        referenceId:
          errorCode.referenceId[
            fieldValidation({
              ...errorCode.referenceIdObj,
              requiredFlag: brand.entityType === 'SOLE_PROPRIETOR',
              fieldval: brand.mobilePhone,
            })
          ],
        email:
          errorCode.email[
            fieldValidation({ ...errorCode.emailObj, fieldval: brand.email })
          ],
        phone: brand.phone
          ? brand.phone === `+${dialCode}`
            ? 'Please enter Support Phone Number'
            : countryIsoCode === 'IN'
            ? brand.phone.length > 13
              ? 'Maximum 10 numbers allowed'
              : ''
            : ''
          : 'Please enter Support Phone Number',
        businessContactEmail:
          brand.entityType === EntityTypes.PubliclyTradedCompany
            ? errorCode.businessContactEmail[
                fieldValidation({
                  ...errorCode.businessContactEmailObj,
                  fieldval: brand.businessContactEmail,
                })
              ]
            : '',
      };
      if (
        Object.keys(validateNewInput).every((k) => {
          return validateNewInput[k] === '';
        })
      ) {
        if (
          Object.keys(validateNewInput).every((k) =>
            brand[k as KeyOfBrand] ? isValidInput(brand[k as KeyOfBrand]) : true
          )
        ) {
          handleSubmitEdit(brand);
        } else {
          Object.keys(validateNewInput).forEach((k: string) => {
            if (
              !isValidInput(brand[k as KeyOfBrand]) &&
              brand[k as KeyOfBrand]
            ) {
              setError((prevError) => ({
                ...prevError,
                [k]: 'Invalid input',
              }));
            }
          });
        }
      } else {
        setError(validateNewInput);
      }
    } else if (type === EditModal.contact) {
      const dialCode = countryIsoCode
        ? getCountryCallingCode(countryIsoCode)
        : '';
      const validateNewInput: Record<string, string> = {
        email:
          errorCode.email[
            fieldValidation({ ...errorCode.emailObj, fieldval: brand.email })
          ],
        phone: brand.phone
          ? brand.phone === `+${dialCode}`
            ? 'Please enter Support Phone Number'
            : countryIsoCode === 'IN'
            ? brand.phone.length > 13
              ? 'Maximum 10 numbers allowed'
              : ''
            : ''
          : 'Please enter Support Phone Number',
      };
      if (
        Object.keys(validateNewInput).every((k) => {
          return validateNewInput[k] === '';
        })
      ) {
        const formData = {
          email: brand.email,
          phone: formatPhoneNumberIntl(brand.phone as PhoneNumberInputValue)
            ? formatPhoneNumberIntl(brand.phone as PhoneNumberInputValue)
            : (brand.phone as string),
        };
        if (
          Object.keys(validateNewInput).every((k) =>
            isValidInput(brand[k as KeyOfBrand])
          )
        ) {
          handleSubmitEdit(formData);
        } else {
          Object.keys(validateNewInput).forEach((k) => {
            if (!isValidInput(brand[k as KeyOfBrand])) {
              setError({
                ...error,
                [k]: 'Invalid ',
              });
            }
          });
        }
      } else {
        setError(validateNewInput);
      }
    } else if (type === EditModal.companyName) {
      if (brand.companyName && brand.companyName.trim()) {
        if (isValidInput(brand.companyName)) {
          const formData = {
            companyName: brand.companyName,
          };
          handleSubmitEdit(formData);
        } else {
          setError({
            companyName: 'Invalid input',
          });
        }
      } else {
        setError({
          companyName: 'Please enter Legal Company Name',
        });
      }
    } else if (type === EditModal.brandRelationship) {
      const formData = {
        brandRelationshipScore: brand.brandRelationshipScore,
      };
      handleSubmitEdit(formData);
    }
  };

  const displayTitle = (modalType: EditModal) => {
    const Wrapper: FC<{ className?: string }> = ({
      children,
      className = '',
    }) => (
      <Flex
        className={className}
        sx={{
          width: '100%',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          mb: 'xs',
          gap: 'xs',
          color: 't.black80',
        }}
      >
        {children}
      </Flex>
    );

    const Title: FC<{ icon: any }> = ({ children, icon }) => {
      return (
        <>
          <div>
            <FontAwesomeIcon icon={icon} size="xl" />
          </div>
          <Box
            as="h3"
            sx={{
              fontSize: '20px',
              fontWeight: 'semiBold',
              lineHeight: 'normal',
            }}
          >
            {children}
          </Box>
        </>
      );
    };

    switch (modalType) {
      case EditModal.company:
        return (
          <Wrapper>
            <Title icon={faBuildingCircleCheck}>Company Details</Title>
          </Wrapper>
        );
      case EditModal.companyName:
        return (
          <Wrapper>
            <Title icon={faTag}>Legal Company Name</Title>
          </Wrapper>
        );
      case EditModal.brandRelationship:
        return (
          <Wrapper className="title brandRelationship">
            <Title icon={faChartNetwork}>Brand Relationship</Title>
            <ToolTip
              heading
              title={
                'The “Brand Relationship” reflects the business history you have with a specific brand.'
              }
            />
          </Wrapper>
        );
      default:
        return '';
    }
  };

  useEffect(() => {
    if (open) {
      setBrand(brandInfo);
      setError({});
    }
  }, [open, brandInfo]);

  const fetchValidationRules = (query = { entityType: `BRAND` }) => {
    globalGetService(`csp/validationRule`, query)
      .then((response) => {
        if (response && response.status >= 200 && response.status < 300) {
          setValidationRules(response.data ? response.data.records : []);
        }
      })
      .catch(() => {
        // ignore error
      });
  };

  useEffect(() => {
    if (open && brand.entityType !== 'SOLE_PROPRIETOR') {
      fetchValidationRules();
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={(_e, reason) => {
        !(reason === 'backdropClick' || reason === 'escapeKeyDown') &&
          handleClose();
      }}
      aria-labelledby="responsive-dialog-title"
      maxWidth={type === EditModal.companyName ? 'xs' : 'md'}
      fullWidth
      id="successModal"
      className={`edit-modal brand`}
      data-testid="brandEditModal"
    >
      <DialogContent className={`edit-content brand`}>
        <Grid container>
          {displayTitle(type)}

          {type === EditModal.company && (
            <Flex
              sx={{
                flexDirection: 'column',
                gap: '6px',
                width: '100%',
                fontWeight: 'normal',
                fontSize: 'H300',
                lineHeight: 'H400',
                color: 't.grey500',
              }}
            >
              {brandInfo.entityType === EntityTypes.PubliclyTradedCompany
                ? 'Editing the EIN, EIN Issuing Country, Legal Company Name, Entity Type, or Business Email Address will result in the brand’s identity status changing to UNVERIFIED. To return to a VERIFIED status and create new campaigns, resubmit the brand for identity verification by clicking "Resubmit Brand" on the top-right corner of the page. Please note that the standard $4.00 fee will be applied for identity verification. You cannot change any brand information without adding a business email address.'
                : 'Editing EIN, EIN Issuing Country, Legal Company Name, Entity Type will result in Identity Status being changed to UNVERIFIED. In order to obtain another VERIFIED status and create new campaigns, you will need to resubmit the brand for Identity Verification by clicking "Resubmit" on the top-right corner of the page. Please note that the standard $4.00 fee will be applied for Identity Verification.'}
              <Box
                as="span"
                sx={{
                  fontSize: 'H100',
                  lineHeight: 'H200',
                }}
              >
                * Indicates a Required Field
              </Box>
            </Flex>
          )}
          <form onSubmit={handleSubmit}>
            {type === EditModal.company ? (
              <EditBrandCompanyDetails
                brand={brand}
                handleChange={handleChange}
                handleError={handleError}
                error={error}
                stockExchanges={stockExchanges}
                verticalTypes={verticalTypes}
                countries={countries}
                usStates={usStates}
                setCountryIsoCode={setCountryIsoCode}
              />
            ) : type === EditModal.companyName ? (
              <Grid container>
                <Box
                  as="p"
                  sx={{
                    fontWeight: 'normal',
                    fontSize: 'H300',
                    lineHeight: 'H500',
                    mb: 'm',
                    color: 't.grey500',
                  }}
                >
                  Editing Legal Company Name will result in Identity Status
                  being changed to UNVERIFIED. In order to obtain another
                  VERIFIED status and create new campaigns, you will need to
                  resubmit the brand for Identity Verification by clicking the
                  "Resubmit" button. Please note that the standard $4.00 fee
                  will be applied for Identity Verification.
                </Box>
                {!isValidForIdentityStatusChange(brand) && (
                  <Box
                    as="p"
                    sx={{
                      fontWeight: 'normal',
                      fontSize: 'H300',
                      lineHeight: 'H500',
                      mb: 'm',
                      color: '#00698F',
                      cursor: 'pointer',
                      textDecoration: 'underline',
                    }}
                    onClick={() => onTypeChange?.(EditModal.company)}
                  >
                    Provide a business contact email before updating this
                    information.
                  </Box>
                )}
                <Grid item xs={12}>
                  <div className="form-group-field">
                    <TextField
                      fullWidth
                      disabled={!isValidForIdentityStatusChange(brand)}
                      value={brand.companyName}
                      label="Legal Company Name"
                      inputProps={{
                        'data-testid': 'brandEditModalCompanyNameInput',
                      }}
                      // eslint-disable-next-line @typescript-eslint/no-explicit-any
                      onChange={(e: any) =>
                        handleChange(e.target.value, EditModal.companyName)
                      }
                      // eslint-disable-next-line @typescript-eslint/no-explicit-any
                      onBlur={(e: any) =>
                        handleError(e.target.value, EditModal.companyName)
                      }
                    />
                    {error.companyName ? (
                      <h6 className="error-msg">{error.companyName}</h6>
                    ) : null}
                  </div>
                </Grid>
              </Grid>
            ) : type === EditModal.brandRelationship ? (
              <BrandTrustScore
                brandInfo={brand}
                error={error}
                handleChange={handleChange}
              />
            ) : null}
          </form>
          <Grid item xs={12} className="footer">
            <li>
              <p>
                <a
                  className={'secondary-btn'}
                  onClick={handleClose}
                  data-testid="brandEditModalCancelButton"
                >
                  Cancel
                </a>
              </p>
            </li>
            <li>
              <p>
                <a
                  className={
                    loader ||
                    Object.keys(error).some((k) => error[k] !== '') ||
                    (type === EditModal.companyName &&
                      !isValidForIdentityStatusChange(brand))
                      ? 'primary-btn disabled'
                      : 'primary-btn'
                  }
                  onClick={handleSubmit}
                  data-testid="brandEditModalSubmitButton"
                >
                  {loader ? 'Saving...' : 'Save'}
                </a>
              </p>
            </li>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};
export default BrandEditModal;
