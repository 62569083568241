import { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { withOktaAuth } from '@okta/okta-react';

import { Loader } from '../shared_elements';
import { toastFlashMessage } from '../utils';
import { globalGetService } from '../utils/globalApiServices';

const statusArray = ['review', 'reject', 'appeal', 'suspend', 'queue'];

function GetCspVerificationHoc(ComposedComponent, extraInfo) {
  class GetCspVerificationHoc extends Component {
    constructor(props) {
      super(props);
      this.state = {
        loader: true,
      };
    }

    componentDidMount() {
      const userDetail = localStorage.getItem('okta-token-storage')
        ? JSON.parse(localStorage.getItem('okta-token-storage'))
        : {};
      if (typeof userDetail === 'object' && userDetail !== null) {
        if (!(userDetail.idToken && userDetail.accessToken)) {
          // toastFlashMessage('Please login again', 'success')
          localStorage.clear();
          this.props.history.push('/login');
        } else {
          globalGetService('csp/profile', {})
            .then((response) => {
              if (response && response.status >= 200 && response.status < 300) {
                const cspInfo = response.data;
                const status = cspInfo.status.toLowerCase();
                if (status == 'active') {
                  this.props.history.push('/dashboard');
                } else if (statusArray.includes(status)) {
                  this.props.history.push('/user/csp/status');
                }
              } else if (response.status == 401) {
                toastFlashMessage(
                  'Token expired! please try logging in again',
                  'error'
                );
                setTimeout(() => {
                  localStorage.clear();
                  // sessionStorage.setItem('logoutSuccess', true);
                  this.props.oktaAuth.signOut('/login');
                }, 1000);
              } else if (response.status >= 400) {
                this.props.history.push('/user/csp');
              }
            })
            .catch((error) => {
              console.log('errorCspProfileHoc', error);
            });
        }
      } else {
        // toastFlashMessage('Please login again', 'success')
        localStorage.clear();
        this.props.history.push('/login');
      }
    }

    render() {
      const { loader } = this.state;
      return <>{loader && <Loader hocFlag />}</>;
    }
  }
  return withRouter(withOktaAuth(GetCspVerificationHoc));
}
export default GetCspVerificationHoc;
