import React from 'react';
import { TableCell, TableRow } from '@material-ui/core';
import { formatPhoneNumberIntl } from 'react-phone-number-input';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faTrash } from '@fortawesome/pro-regular-svg-icons';
import { Box, RoleGuard } from 'portal-commons';

export default function ResellerListingRow(props) {
  const { data } = props;
  return (
    <TableRow data-testid="resellerListingRow">
      <TableCell
        align="left"
        style={{ width: '400px' }}
        data-testid="resellerListingRowCompanyName"
      >
        {data.companyName}
      </TableCell>
      <TableCell align="left" style={{}} data-testid="resellerListingRowUid">
        {data.uid}
      </TableCell>
      <TableCell align="left" style={{}} data-testid="resellerListingRowEmail">
        {data.email}
      </TableCell>
      <TableCell
        align="left"
        style={{}}
        data-testid="resellerListingRowEditButton"
      >
        <div className="edit-col">
          <RoleGuard
            feature="resellerList.editReseller"
            render={(authorized) => (
              <FontAwesomeIcon
                icon={faPen}
                className="edit"
                onClick={() => props.handleEdit(true, data)}
                style={
                  authorized ? {} : { pointerEvents: 'none', opacity: 0.4 }
                }
                size="lg"
              />
            )}
          />
          <RoleGuard
            feature="resellerList.deleteReseller"
            render={(authorized) => (
              <FontAwesomeIcon
                icon={faTrash}
                onClick={() => props.handleDelete(true, data.uid)}
                style={
                  authorized ? {} : { pointerEvents: 'none', opacity: 0.4 }
                }
                size="lg"
              />
            )}
          />
        </div>
        <p className="paragraph" style={{ marginTop: 2, fontSize: 12 }}>
          {formatPhoneNumberIntl(data.phone)
            ? formatPhoneNumberIntl(data.phone)
            : data.phone}
        </p>
      </TableCell>
    </TableRow>
  );
}
