import React, { Fragment } from 'react';
import Drawer from '@material-ui/core/Drawer';
import SideNavigation from './SideNavigation';
import MenuIcon from '@material-ui/icons/Menu';
import { S3_ASSETS_PATH } from '../../constants';
import { Grid, Menu, MenuItem, Fade } from '@material-ui/core';
import { toastFlashMessage } from '../../utils';
import { Redirect, withRouter } from 'react-router-dom';
import { globalGetService } from '../../utils/globalApiServices';
import { RoleGuard } from 'portal-commons';

class MobileHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      flag: false,
      anchorEl: null,
      logoutFlag: false,
      profileView: false,
    };
  }

  componentDidMount() {
    if (localStorage.getItem('okta-token-storage')) {
      globalGetService('csp/profile', {}).then((response) => {
        if (response.status >= 200 && response.status < 300) {
          const cspInfo = response.data;
          if (cspInfo.status == 'ACTIVE') {
            this.setState({ profileView: true });
          }
        }
      });
    }
  }

  handleLogout = () => {
    localStorage.clear();
    this.setState({
      logoutFlag: true,
    });
    toastFlashMessage('Logged out successfully', 'success');
  };

  handleClose = () => {
    this.setState((prevState) => ({
      flag: !prevState.flag,
    }));
  };

  handleClick = (event) => {
    const anchorEl = event.currentTarget;
    this.setState((prevState) => ({
      flag: !prevState.flag,
      anchorEl,
    }));
  };

  render() {
    const { flag, anchorEl, logoutFlag, profileView } = this.state;
    if (logoutFlag) {
      return <Redirect to="/login" />;
    }
    const userName = localStorage.getItem('okta-token-storage')
      ? JSON.parse(localStorage.getItem('okta-token-storage')).idToken.claims
          .name
      : '';
    return (
      <Fragment>
        <header data-testid="mobileHeader">
          <Grid
            container
            className="mobile-header"
            spacing={0}
            alignItems="center"
          >
            <Grid item xs={3}>
              <MenuIcon
                className="menu-icon"
                data-testid="mobileHeaderIcon"
                onClick={() => this.props.toggleDrawer(true)}
              />
              <Drawer
                anchor="left"
                data-testid="mobileHeaderDrawer"
                open={this.props.open}
                onClose={() => this.props.toggleDrawer(false)}
              >
                <SideNavigation path={this.props.path}></SideNavigation>
              </Drawer>
            </Grid>
            {/* <Grid item xs={4}>
                    <div className="header-logo">
                          <Link to="/">
                              <img src={`${S3_ASSETS_PATH}/images/Campaignregistry.svg`} alt="logo" />
                          </Link>
                       </div>
                </Grid> */}
            {userName ? (
              <Grid item xs={9} className="user-dropdown">
                <ul
                  aria-controls="fade-menu"
                  data-testid="mobileHeaderMenuButton"
                  onClick={this.handleClick}
                >
                  <li>
                    <div className="header-avatar">
                      <img src={`${S3_ASSETS_PATH}/images/user-wing.svg`} />
                      <h2>{userName[0]}</h2>
                    </div>
                  </li>
                  <li>
                    Hi <span>{userName}</span>
                  </li>
                  <li>
                    <img
                      src={`${S3_ASSETS_PATH}/images/down-arrow.svg`}
                      alt="down-arrow"
                    />
                  </li>
                </ul>
                <Menu
                  id="fade-menu"
                  data-testid="mobileHeaderMenuList"
                  anchorEl={anchorEl}
                  keepMounted
                  open={flag}
                  onClose={this.handleClose}
                  TransitionComponent={Fade}
                  className="header-drop-down"
                  // classes={{root : styles.root}}
                >
                  {profileView ? (
                    <RoleGuard feature="profile.view">
                      <MenuItem
                        data-testid="mobileHeaderProfileButton"
                        onClick={() => this.props.history.push('/profile')}
                      >
                        View CSP Profile
                      </MenuItem>
                    </RoleGuard>
                  ) : null}
                  <MenuItem
                    data-testid="mobileHeaderLogoutButton"
                    onClick={this.handleLogout}
                  >
                    Logout
                  </MenuItem>
                </Menu>
              </Grid>
            ) : (
              ''
            )}
          </Grid>
        </header>
        <div style={{ height: 50 }}></div>
      </Fragment>
    );
  }
}
export default withRouter(MobileHeader);
