import { Flex, RoleGuard } from 'portal-commons';
import { FunctionComponent } from 'react';
import {
  faDollar,
  faEnvelope,
  faGearCode,
  faIndustryWindows,
  faMessageDots,
  faPen,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import EmailPreferencesSubsection from './EmailPreferencesSubsection';
import Section from '../../../../shared_elements/components/Section';

interface EmailPreferencesSectionProps {
  emails?: string[];
  escalateEmails?: string[];
  financeEmails?: string[];
  techEmails?: string[];
  onEdit?: () => void;
}

const EmailPreferencesSection: FunctionComponent<
  EmailPreferencesSectionProps
> = ({
  emails = [],
  escalateEmails = [],
  financeEmails = [],
  techEmails = [],
  onEdit,
}) => {
  return (
    <Section
      icon={faEnvelope}
      title="Email Preferences"
      action={
        <RoleGuard feature="profile.editEmail">
          <Flex
            sx={{
              flexDirection: 'row',
              columnGap: '4px',
              color: '#0091B3',
              fontFamily: 'Roboto',
              fontWeight: 400,
              fontSize: '14px',
              lineHeight: '16px',
              cursor: 'pointer',
            }}
            onClick={onEdit}
            data-testid="editEmailPreferencesButton"
          >
            <FontAwesomeIcon icon={faPen} />
            <span>Edit</span>
          </Flex>
        </RoleGuard>
      }
      testId="emailPreferencesSection"
    >
      <Flex sx={{ flexDirection: 'column', rowGap: '30px' }}>
        <EmailPreferencesSubsection
          icon={faIndustryWindows}
          title="Commercial Contact Email"
          description="Used for contract and business communications."
          emails={emails}
          testId="emailsPreferencesSubsection"
        />
        <EmailPreferencesSubsection
          icon={faMessageDots}
          title="System Notification Email"
          description="Used for system event notification."
          emails={escalateEmails}
          testId="escalateEmailsPreferencesSubsection"
        />
        <EmailPreferencesSubsection
          icon={faDollar}
          title="Financial Contact Email"
          description="Used for invoicing and finance communications."
          emails={financeEmails}
          testId="financeEmailsPreferencesSubsection"
        />
        <EmailPreferencesSubsection
          icon={faGearCode}
          title="Technical Contact Details"
          description="Complete this using your support team email, it will be used if other emails given do not respond."
          emails={techEmails}
          testId="techEmailsPreferencesSubsection"
        />
      </Flex>
    </Section>
  );
};

export default EmailPreferencesSection;
