import { Flex, RoleGuard } from 'portal-commons';
import { FunctionComponent } from 'react';
import { TableCell, TableRow } from '@material-ui/core';
import { faPen, faTrash } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { ROLES } from '../constants';
import { User } from '../types';
import { dateValueToText } from '../utils';

interface UserListingRowProps {
  user: User;
  onEdit?: () => void;
  onDelete?: () => void;
}

const UserListingRow: FunctionComponent<UserListingRowProps> = ({
  user,
  onEdit,
  onDelete,
}) => {
  return (
    <TableRow data-testid="userListingRow">
      <TableCell data-testid="fullName">{user.profile.fullName}</TableCell>
      <TableCell data-testid="email">{user.profile.email}</TableCell>
      <TableCell data-testid="id">{user.id}</TableCell>
      <TableCell data-testid="role">
        {user.profile.csp_app_role?.join(',') ?? ROLES.REVIEWER}
      </TableCell>
      <TableCell data-testid="created">
        {dateValueToText(user.created)}
      </TableCell>
      <TableCell data-testid="lastLogin">
        {dateValueToText(user.lastLogin)}
      </TableCell>
      <TableCell>
        <Flex
          sx={{
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'flex-end',
            columnGap: '7px',
            fontWeight: 400,
            fontSize: '12px',
            lineHeight: '12px',
          }}
        >
          <RoleGuard feature="profile.editUser">
            <FontAwesomeIcon
              style={{ cursor: 'pointer' }}
              icon={faPen}
              onClick={onEdit}
              data-testid="editUserButton"
            />
          </RoleGuard>
          <RoleGuard feature="profile.deleteUser">
            <FontAwesomeIcon
              style={{ cursor: 'pointer' }}
              icon={faTrash}
              onClick={onDelete}
              data-testid="deleteUserButton"
            />
          </RoleGuard>
        </Flex>
      </TableCell>
    </TableRow>
  );
};

export default UserListingRow;
