import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Grid, Container } from '@material-ui/core';

import { CspVerificationMessage } from '../components';
import { getCspProfile } from '../../../../apis';
import { S3_ASSETS_PATH } from '../../../../constants';
import { withAuthContext } from '../../../../contexts';
import { Loader } from '../../../../shared_elements';

import '../../../../assets/styles/csp-status-module.scss';

class CspVerificationStatus extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loader: true,
      status: '',
      statusData: '',
    };
  }

  async componentDidMount() {
    const { history, cspData, profile, setProfile } = this.props;

    document.title = 'The Campaign Registry - Approval Status';
    const newProfile = profile ?? (await getCspProfile());
    if (newProfile) {
      const newStatus = newProfile.status.toLowerCase();
      if (newStatus === 'active') {
        history.push('/dashboard');
        if (!profile) {
          setProfile(newProfile);
        }
      } else {
        const status = ['review', 'queue', 'appeal'].includes(newStatus)
          ? 'pending'
          : `${newStatus}ed`;
        const statusData = cspData
          ? cspData.find(
              (item) => item.lookup_key === `csp-verification-status#${status}`
            )
          : '';
        this.setState({
          loader: false,
          status,
          statusData,
        });
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { cspData } = this.props;
    const { loader, status, statusData } = this.state;
    if (!loader && !statusData && cspData.length) {
      const statusData = cspData.find(
        (item) => item.lookup_key == `csp-verification-status#${status}`
      );
      if (statusData) {
        this.setState({
          statusData,
        });
      }
    }
  }

  render() {
    const { loader, status, statusData } = this.state;
    return (
      <section
        className="csp-verification-status-section"
        data-testid="cspVeriricationStatus"
      >
        {loader || !statusData ? (
          <Loader />
        ) : (
          <Container
            style={{ padding: '0px' }}
            className="csp-verification-status-container"
          >
            <Grid
              container
              spacing={0}
              justify="center"
              alignItems="center"
              className="csp-status-outer-wrapper"
            >
              <CspVerificationMessage
                type={status}
                icon={`${S3_ASSETS_PATH}/images/${status}-status.svg`}
                title={statusData.title}
                content={statusData.text}
              />
            </Grid>
          </Container>
        )}
      </section>
    );
  }
}
const mapStateToProps = (state) => ({
  cspData: state.shareReducer.cspData,
});
export default connect(mapStateToProps)(withAuthContext(CspVerificationStatus));
