import React, { CSSProperties, useState } from 'react';
import { Grid, Menu, MenuItem, makeStyles } from '@material-ui/core';
import { Link, useHistory } from 'react-router-dom';
import { deleteBrandApi, sendOtp, verifyOtp } from '../../apiServices';
import { toastFlashMessage } from '../../../../../utils';
import { Flex, RoleGuard, BoxV2 as Box } from 'portal-commons';
import ToolTip from '../../../../../ui_elements/ToolTip';
import ConfirmationDialog from '../ConfirmationDialog';
import TriggerOtpModal from '../TriggerOtpModal';
import EnterOtpModal from '../EnterOtpModal';

import { BrandDetail } from '../../types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/pro-regular-svg-icons';

const useStyles = makeStyles({
  menu: {
    display: 'flex',
    flexDirection: 'column',
  },
});

interface Props {
  brandUid: string;
  brand: BrandDetail;
  handleEditClick: (_type: string) => void;
  getBrandDetails: (_id: string) => void;
}

const tooltipOverriddenStyle: CSSProperties = {
  fontSize: 'inherit',
  position: 'relative',
  bottom: '2px',
  top: 'unset',
  right: 'unset',
  marginLeft: '5px',
};

interface LocationState {
  goBackPage?: string;
}

const BrandBasicDetails: React.FC<Props> = ({
  brandUid,
  brand,
  handleEditClick,
  getBrandDetails,
}) => {
  const history = useHistory<LocationState>();
  const classes = useStyles();
  const isMoreDisabled = brand.identityStatus ? false : true;
  const [moreAction, setMoreAction] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [deleteBrandConfirm, setDeleteBrandConfirm] = useState(false);
  const [deleteBrandLoader, setDeleteBrandLoader] = useState(false);
  const [triggerOtpModal, setTriggerOtpModal] = useState(false);
  const [enterOtpModal, setEnterOtpModal] = useState(false);

  const handleBrandActionMenu = (e: React.MouseEvent<HTMLButtonElement>) => {
    setMoreAction(true);
    setAnchorEl(e.currentTarget);
  };

  const handleBrandActionMenuClose = () => {
    setMoreAction(false);
    setAnchorEl(null);
  };

  const deleteBrandByID = async (brandId: string) => {
    const response = await deleteBrandApi(brandId);
    setDeleteBrandLoader(false);
    setDeleteBrandConfirm(false);
    if (response.status >= 200 && response.status < 300) {
      toastFlashMessage('Brand deleted successfully', 'success');
      history.push(history.location.state?.goBackPage || '/brands');
    }
  };

  const handleSubmitDeleteBrand = (flag: boolean) => {
    if (flag) {
      setDeleteBrandLoader(true);
      deleteBrandByID(brandUid);
    } else {
      setDeleteBrandConfirm(false);
    }
  };

  const handleOtpSend = async (message: string) => {
    try {
      await sendOtp(brandUid, message);
      toastFlashMessage(
        'OTP sent successfully and will expire in 24 hours',
        'success'
      );
    } catch (e) {
      // Ignore
    }
  };

  const handleOtpVerify = async (otp: string) => {
    try {
      await verifyOtp(brandUid, otp);
      toastFlashMessage('OTP verified', 'success');
      await getBrandDetails(brandUid);
    } catch (e) {
      // Ignore
    }
  };

  return (
    <React.Fragment>
      <Grid
        container
        className="brand-details basic"
        justifyContent="center"
        data-testid="brandBasicDetails"
      >
        <Grid item xs={12} md={12} className="brand-heading">
          <div className="title">
            <h3 className="heading1">
              <span style={{ fontWeight: 400 }}>
                Legal Company Name&#58;&nbsp;
              </span>
              <span className="company-name" style={{ marginLeft: '2px' }}>
                {brand.companyName ? brand.companyName : 'N/A'}
              </span>
              <div className="brand-name-btns">
                <ToolTip
                  placement="right-end"
                  heading
                  title="Legally registered company name."
                  testid="brandBasicDetailsToolTip"
                  style={{ ...tooltipOverriddenStyle, height: '18px' }}
                />
                {brand.entityType !== 'SOLE_PROPRIETOR' && (
                  <RoleGuard feature="brandDetail.editCompanyName">
                    <div
                      data-testid="brandBasicDetailsEditButton"
                      className="edit-btn"
                      onClick={() => handleEditClick('companyName')}
                    >
                      <FontAwesomeIcon icon={faPen} color="#0091B3" />
                    </div>
                  </RoleGuard>
                )}
              </div>
            </h3>

            <div className="float-right">
              <RoleGuard feature="brandDetail.viewMoreActions">
                <React.Fragment>
                  <button
                    disabled={isMoreDisabled}
                    className={`${isMoreDisabled ? 'disabled' : ''} ${
                      moreAction ? 'open brand-action-btn' : 'brand-action-btn'
                    }`}
                    aria-controls="moreActionMenu"
                    aria-haspopup="true"
                    aria-expanded={moreAction ? 'true' : undefined}
                    onClick={handleBrandActionMenu}
                    aria-label="more"
                    data-testid="brandBasicDetailsActionButton"
                  >
                    <span></span>
                    <span></span>
                    <span></span>
                  </button>
                  <Menu
                    disableAutoFocusItem={isMoreDisabled}
                    id="moreActionMenu"
                    classes={{ list: classes.menu }}
                    anchorEl={anchorEl}
                    open={moreAction}
                    onClose={handleBrandActionMenuClose}
                    getContentAnchorEl={null}
                    data-testid="brandBasicDetailsMoreActionMenu"
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                  >
                    <MenuItem onClick={handleBrandActionMenuClose}>
                      <Link
                        data-testid="brandBasicDetailsAddNewCampaignButton"
                        to={{
                          pathname: '/campaign/create',
                          state: {
                            brandUid: brand.uid,
                            brandName: brand.displayName,
                          },
                        }}
                      >
                        Add new Campaign
                      </Link>
                    </MenuItem>

                    <MenuItem
                      data-testid="brandBasicDetailsDeleteBrandButton"
                      onClick={() => {
                        handleBrandActionMenuClose();
                        setDeleteBrandConfirm(true);
                      }}
                    >
                      Delete Brand
                    </MenuItem>
                    <MenuItem
                      data-testid="brandBasicDetailsTriggerOTPButton"
                      onClick={() => {
                        handleBrandActionMenuClose();
                        setTriggerOtpModal(true);
                      }}
                    >
                      Trigger OTP
                    </MenuItem>
                    <MenuItem
                      data-testid="brandBasicDetailsEnterOTPButton"
                      onClick={() => {
                        handleBrandActionMenuClose();
                        setEnterOtpModal(true);
                      }}
                    >
                      Enter OTP Pin
                    </MenuItem>
                  </Menu>
                </React.Fragment>
              </RoleGuard>
            </div>
          </div>
        </Grid>
        <Flex sx={{ alignItems: 'center', gap: 21 }}>
          <Flex
            data-testid="brandBasicDetailsBrandIdContainer"
            sx={{
              gap: 4,
              color: 't.grey500',
              alignItems: 'center',
              fontSize: 'H400',
              fontWeight: 400,
              lineHeight: '19px',
            }}
          >
            <span>Brand ID: </span>
            <Box as="span" sx={{ fontWeight: 700 }}>
              {brand.uid ? brand.uid : 'N/A'}
            </Box>
          </Flex>
          <Box sx={{ width: 1, height: '100%', background: '#EDEEEF' }} />
          <Flex
            data-testid="brandBasicDetailsUniversalEinContainer"
            sx={{
              gap: 4,
              color: 't.grey500',
              alignItems: 'center',
              fontSize: 'H400',
              fontWeight: 400,
              lineHeight: '19px',
            }}
          >
            <span>Universal EIN: </span>
            <Box as="span" sx={{ fontWeight: 700, position: 'relative' }}>
              {brand.universalEin ? (
                <React.Fragment>
                  {brand.universalEin}
                  <ToolTip
                    placement="right-end"
                    heading
                    title="A unique identifier generated by TCR and composed of the country code and the EIN/Tax Number"
                    style={tooltipOverriddenStyle}
                  />
                </React.Fragment>
              ) : (
                'N/A'
              )}
            </Box>
          </Flex>
        </Flex>
      </Grid>

      <ConfirmationDialog
        open={deleteBrandConfirm}
        content={`
          Are you sure you want to
          <br/><br/>
          <b>delete this brand</b>?
          <br/><br/>
          This action is irrevocable.
        `}
        submitText={'Delete'}
        handleClick={handleSubmitDeleteBrand}
        loader={deleteBrandLoader}
      />
      <TriggerOtpModal
        open={triggerOtpModal}
        onClose={() => {
          setTriggerOtpModal(false);
        }}
        onSubmit={handleOtpSend}
      />
      <EnterOtpModal
        open={enterOtpModal}
        onClose={() => {
          setEnterOtpModal(false);
        }}
        onSubmit={handleOtpVerify}
      />
    </React.Fragment>
  );
};

export default BrandBasicDetails;
