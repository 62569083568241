import React, { FC, useState } from 'react';
import { Tooltip, ClickAwayListener, makeStyles } from '@material-ui/core';
import { ExtVettingRecord } from '../../types';
import { toastFlashMessage } from '../../../../../utils';
import { Box } from 'portal-commons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/pro-regular-svg-icons';

const useStyles = makeStyles({
  tooltip: {
    '&.MuiTooltip-tooltip': {
      borderRadius: '3px',
      background: '#FFFFFF',
      color: '#0091B3',
      fontSize: 14,
      maxWidth: 526,
      fontFamily: 'Roboto',
      fontWeight: 400,
      padding: 0,
      boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    },
  },
});

interface ScoreTooltipProps {
  data: ExtVettingRecord;
}

const ScoreTooltip: FC<ScoreTooltipProps> = ({ data }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  const hasVettingReport = (record: ExtVettingRecord) => {
    return (
      record &&
      (record.reasons ||
        (record.vettingClass === 'POLITICAL' &&
          (record.evpUid === 'AEGIS'
            ? record.additionalRequestPayload
            : record.fullReport)))
    );
  };

  const remapStringReasonsToUITemplate = (stringReason: string) => {
    if (!stringReason) {
      return [];
    }

    let array = [];
    try {
      array = JSON.parse(stringReason);
    } catch (e) {
      console.error('Invalid JSON string:', e);
      return [];
    }

    if (!Array.isArray(array)) {
      console.error('Parsed value is not an array: ', array);
      return [];
    }

    return array.map((reason: string, index: number) => (
      <li key={`reasons-${index}`}>{reason}</li>
    ));
  };

  const getCopyTextTemplate = (text: string, keyName: string) => {
    if (!text) {
      return undefined;
    }
    return (
      <div
        className="p-l-xs pointer copy"
        style={{ marginRight: 17 }}
        onClick={() => {
          navigator.clipboard.writeText(text);
          toastFlashMessage(
            `${keyName} has been copied to your clipboard`,
            'success'
          );
        }}
      >
        copy
      </div>
    );
  };

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <Tooltip
        PopperProps={{
          disablePortal: true,
          popperOptions: {
            positionFixed: true,
            modifiers: {
              preventOverflow: {
                enabled: true,
                boundariesElement: 'window',
              },
            },
          },
        }}
        classes={{ tooltip: classes.tooltip }}
        open={open}
        onClose={handleTooltipClose}
        disableFocusListener
        disableHoverListener
        disableTouchListener
        placement={'bottom-end'}
        title={
          <div
            className="vetting-details-tooltip"
            data-testid="vettingDetailsTooltipContainer"
          >
            <div className="paragraph title">
              Please see the vetting details below:
            </div>
            {hasVettingReport(data) && (
              <div className="reasons-container" style={{ overflowY: 'unset' }}>
                <ul className="vetting-detail-list-tooltip">
                  {data?.reasons
                    ? remapStringReasonsToUITemplate(data.reasons)
                    : null}
                  {data.vettingClass === 'POLITICAL' &&
                    (data.evpUid === 'AEGIS' ? (
                      <>
                        {data.additionalRequestPayload &&
                          Object.entries(
                            JSON.parse(data.additionalRequestPayload)
                          ).map(([key, value]) => (
                            <li key={key}>
                              {key}: {value}
                            </li>
                          ))}
                      </>
                    ) : (
                      <>
                        {data.fullReport &&
                          Object.entries(JSON.parse(data.fullReport)).map(
                            ([key, value]) => (
                              <li key={key}>
                                {key}: {value}
                              </li>
                            )
                          )}
                      </>
                    ))}
                </ul>
              </div>
            )}

            <div
              className="copy-paste-container"
              data-testid="brandVettingDetailsBlockCopyAndPasteContainer"
            >
              {hasVettingReport(data) && <hr />}
              {data.vettingClass === 'POLITICAL' ? (
                <>
                  <div className="flex space-between">
                    <div className="flex-1 flex space-between text-trucate">
                      <p className="flex-2 text-trucate">
                        <span>Vetting ID: </span> {data.vettingId || 'N/A'}
                      </p>
                      {getCopyTextTemplate(data.vettingId, 'Vetting ID')}
                    </div>
                    <div className="flex-1 flex space-between text-trucate">
                      <p className="flex-2 text-trucate">
                        <span>Vetting Token: </span>{' '}
                        {data.vettingToken || 'N/A'}
                      </p>
                      {data.vettingToken &&
                        getCopyTextTemplate(data.vettingToken, 'Vetting Token')}
                    </div>
                  </div>
                </>
              ) : data.evpUid === 'WMC' ? (
                <>
                  <div className="flex space-between">
                    <div className="flex-1 flex space-between text-trucate">
                      <p className="flex-2 text-trucate">
                        <span>Vetting ID/Transaction ID: </span>
                        {data.vettingId || 'N/A'}
                      </p>
                      {getCopyTextTemplate(data.vettingId, 'Vetting ID')}
                    </div>
                    <div className="flex-1 flex space-between text-trucate">
                      <p className="flex-2 text-trucate">
                        <span>Vetting Token: </span>
                        {data.vettingToken || 'N/A'}
                      </p>
                      {data.vettingToken &&
                        getCopyTextTemplate(data.vettingToken, 'Vetting Token')}
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="flex space-between">
                    <div className="flex-1 flex space-between text-trucate">
                      <p className="flex-2 text-trucate">
                        <span>Vetting ID: </span> {data.vettingId || 'N/A'}
                      </p>
                      {getCopyTextTemplate(data.vettingId, 'Vetting ID')}
                    </div>
                    <div className="flex-1 flex space-between text-trucate">
                      <p className="flex-2 text-trucate">
                        <span>Vetting Token: </span>{' '}
                        {data.vettingToken || 'N/A'}
                      </p>
                      {data.vettingToken &&
                        getCopyTextTemplate(data.vettingToken, 'Vetting Token')}
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        }
      >
        <span
          data-testid="brandVettingDetailsBlockViewIconButton"
          onClick={handleTooltipOpen}
          className={`${open ? 'active' : ''} view-icon`}
        >
          <Box
            style={{
              marginTop: '3px',
              marginLeft: '3px',
              cursor: 'pointer',
            }}
          >
            <FontAwesomeIcon icon={faEye} />
          </Box>
        </span>
      </Tooltip>
    </ClickAwayListener>
  );
};

export default ScoreTooltip;
