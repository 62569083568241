import { AppLayout, RoleGuard } from 'portal-commons';
import { ComponentType, useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import { useLocation } from 'react-router-dom';
import { useOktaAuth } from '@okta/okta-react';

import NoAccess from '../NoAccess';
import { useAuthContext } from '../contexts';
import { Header, MobileHeader, SideNavigation } from '../shared_elements';
import { Role } from '../types';
import { toastFlashMessage } from '../utils';

const withAppLayout =
  <P extends Record<string, never>>(
    WrappedComponent: ComponentType<P>,
    options: Record<string, any> = {}
  ) =>
  (props: P) => {
    const { role } = useAuthContext();
    const { authState } = useOktaAuth();
    const location = useLocation();
    const cspFlag = location.pathname === '/user/csp';
    const roles: Role[] = options.roles ?? [];

    useEffect(() => {
      if (authState.isAuthenticated && role) {
        const loginFlag = localStorage.getItem('loginSuccess');
        if (loginFlag === '1') {
          toastFlashMessage('Logged in successfully', 'success');
          localStorage.removeItem('loginSuccess');
        }
      }
    }, [authState.isAuthenticated, role]);

    return (
      <AppLayout
        authenticated={role && roles.includes(role)}
        sideNavigation={isMobile ? <MobileHeader /> : <SideNavigation />}
        topBar={<Header title={options.title} testId="hocHeader" />}
        footer={
          <div className="footer-hoc-block">
            <div className="text-center">
              <p>
                Confidentiality Notice: The information accessed through The
                Campaign Registry CSP Portal is intended for use by authorized
                individuals only. Any unauthorized access or use of this
                information is prohibited. By using The Campaign Registry CSP
                Portal, you agree to keep all accessed confidential in
                accordance with the{' '}
                <a
                  href="https://www.campaignregistry.com/TCR-T&Cs.pdf"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Terms of Use
                </a>
                .
              </p>
            </div>
          </div>
        }
      >
        <div
          className={`flex-1 header-hoc-block ${cspFlag ? 'csp' : ''}`}
          style={
            isMobile
              ? { padding: 20 }
              : location.pathname.includes('/404')
              ? { padding: 0 }
              : {}
          }
        >
          <RoleGuard roles={roles} fallback={<NoAccess />}>
            <WrappedComponent {...props} />
          </RoleGuard>
        </div>
      </AppLayout>
    );
  };

export default withAppLayout;
