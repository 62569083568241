import React, {
  useRef,
  useState,
  ChangeEvent,
  DragEvent,
  MouseEvent,
} from 'react';
import { makeStyles, Tooltip, Box } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBan } from '@fortawesome/pro-regular-svg-icons';

import { S3_ASSETS_PATH } from '../../../../../constants';
import LoadingSpinner from '../LoadingSpinner';
import {
  FILE_EXTENSIONS_TOOLTIP_FOR_VETTING_APPEAL,
  MIME_TYPES_FOR_VETTING_APPEAL,
} from '../../constants';

const useStyles = makeStyles({
  container: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '262px',
    padding: '25px 71px 40px 71px',
    border: '1px dashed #949495',
    borderRadius: '4px',
    boxSizing: 'border-box',
    background: '#F4F8FA',
    '&.active': {
      background: '#E1F2FA',
    },
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '14px',
  },
  icon: {
    width: '45px',
    height: '44px',
    objectFit: 'cover',
  },
  prompts: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '6px',
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '14px',
    color: '#68737A',
  },
  button: {
    height: '25px',
    padding: '0 10px',
    border: 'none',
    borderRadius: '200px',
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '16px',
    textTransform: 'none',
    color: '#FFFFFF',
    background: '#00698F',
    cursor: 'pointer',
  },
  notes: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '2px',
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '14px',
    color: '#949495',
  },
  link: {
    textDecoration: 'underline',
    color: '#949495',
    cursor: 'pointer',
  },
  tooltip: {
    '&.MuiTooltip-tooltip': {
      background: '#0091B3',
      color: '#fff',
      fontSize: 12,
      borderRadius: 10,
      maxWidth: 180,
      fontFamily: 'Roboto',
      fontWeight: 400,
    },
  },
  loader: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: '#E1F2FA',
    zIndex: 999,
  },
  mask: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: '#F4F8FA',
    color: '#949495',
    fontSize: '18px',
    fontWeight: 600,
    gap: '14px',
  },
  banIcon: {
    fontSize: '64px',
    color: '#E6E7E7',
  },
  errorMessage: {
    position: 'absolute',
    bottom: '20px',
    left: '50%',
    transform: 'translateX(-50%)',
    color: '#AA0000',
    fontSize: '16px',
  },
});

interface Props {
  loading?: boolean;
  onChange: (_file: File) => unknown;
  disabled?: boolean;
  max: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

const SampleMultimediaUploader: React.FC<Props> = ({
  loading = false,
  onChange,
  disabled = false,
  max,
  ...props
}) => {
  const ref = useRef<HTMLInputElement | null>(null);
  const [active, setActive] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>();
  const classes = useStyles();

  const handleDragStart = (event: React.DragEvent) => {
    event.preventDefault();
    event.stopPropagation();
    setActive(true);
  };

  const handleDragEnd = (event: DragEvent) => {
    event.preventDefault?.();
    event.stopPropagation?.();
    setActive(false);
  };

  const handleDrop = (event: DragEvent) => {
    event.preventDefault();
    event.stopPropagation();
    setActive(false);
    if (event?.nativeEvent.dataTransfer?.files.length) {
      const file = event.nativeEvent.dataTransfer.files[0];
      if (MIME_TYPES_FOR_VETTING_APPEAL.includes(file.type)) {
        onChange && onChange(event.nativeEvent.dataTransfer.files[0]);
      } else {
        setErrorMessage('Unsupported file type');
        setTimeout(() => setErrorMessage(undefined), 1000);
      }
    }
  };

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    event.stopPropagation();
    if (ref.current) {
      ref.current.click();
    }
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event?.target.files?.length) {
      onChange && onChange(event.target.files[0]);
    }
  };

  return (
    <div
      className={`${classes.container} ${active ? 'active' : ''}`}
      onDragEnter={handleDragStart}
      onDragOver={handleDragStart}
      onDragLeave={handleDragEnd}
      onDragEnd={handleDragEnd}
      onDrop={handleDrop}
      {...props}
    >
      <div className={classes.content}>
        <img
          className={classes.icon}
          src={`${S3_ASSETS_PATH}/images/icon-upload.svg`}
        />
        <div className={classes.prompts}>
          <span>Drop files to attach or...</span>
          <button
            className={classes.button}
            onClick={handleClick}
            data-testid="sampleMultimediaUploaderButton"
          >
            Click here to browse
          </button>
        </div>
        <div className={classes.notes}>
          {typeof max === 'number' && <span>Maximum {max} uploads</span>}
          <span>Maximum file size: 10 MB</span>
          <span>Maximum total file size: 30 MB</span>
          <Tooltip
            classes={{ tooltip: classes.tooltip }}
            title={FILE_EXTENSIONS_TOOLTIP_FOR_VETTING_APPEAL}
            placement="right"
          >
            <span className={classes.link}>Approved file types</span>
          </Tooltip>
        </div>
      </div>
      <input
        ref={ref}
        data-testid="SampleMultimediaUploaderInput"
        style={{ display: 'none' }}
        type="file"
        accept={MIME_TYPES_FOR_VETTING_APPEAL.join(',')}
        onChange={handleChange}
      />
      {errorMessage && (
        <div className={classes.errorMessage}>{errorMessage}</div>
      )}
      {loading && (
        <div className={classes.loader}>
          <LoadingSpinner />
        </div>
      )}
      {disabled && (
        <Box className={classes.mask}>
          <FontAwesomeIcon icon={faBan} className={classes.banIcon} />
          <div>Maximum {max} Uploads</div>
        </Box>
      )}
    </div>
  );
};

export default SampleMultimediaUploader;
