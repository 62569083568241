import { Button, RoleGuard, Table, withPermission } from 'portal-commons';
import queryString from 'query-string';
import React, { Component } from 'react';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Container, Grid, withStyles } from '@material-ui/core';

import { getResellerListApi } from '../apiServices';
import {
  ResellerListingRow,
  DeleteResellerModal,
  AddResellerModal,
} from '../components';
import { APP_ROLES, S3_ASSETS_PATH } from '../../../../constants';
import FilterRow from '../../../../shared_elements/containers/FilterRow';
import Loader from '../../../../shared_elements/containers/Loader';

import '../../../../assets/styles/resellers-module.scss';

const filterObject = {
  companyName: {
    filterType: 'text',
    value: '',
    label: 'Company Name',
    placeholder: 'Enter company name',
  },
};

const headRows = [
  { id: 'companyName', label: 'LEGAL COMPANY NAME', sortable: true },
  { id: 'uid', label: 'RESELLER ID', sortable: true },
  { id: 'email', label: 'E-MAIL ADDRESS', sortable: true },
  { id: 'phone', label: 'PHONE NUMBER', sortable: true },
];

const styles = {
  title: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '8px',
    fontFamily: 'Roboto, sans-serif',
    fontWeight: 600,
    fontSize: '27px',
    lineHeight: '32px',
    color: '#19262A',
  },
};

class Resellers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: true,
      filter: {
        sortField: 'createDate',
        ascendingOrder: false,
      },
      resellerInfo: {
        records: [],
        page: 1,
        recordsPerPage: 10,
        totalRecords: 0,
      },
      appliedFilterObj: {},
      filterObject,
      tableLoader: true,
      openFilter: false,
      addReseller: false,
      editReseller: false,
      deleteReseller: false,
    };
    this.getResellerListApi = getResellerListApi.bind(this);
  }

  componentDidMount() {
    const { filter } = this.state;
    if (this.props.location.search) {
      const querys = queryString.parse(this.props.location.search, {
        decode: true,
      });
      let updatedFilterObject = this.state.filterObject;
      let updatedAppliedFilterObject = this.state.appliedFilterObj;
      let updatedFilter = this.state.filter;

      Object.keys(querys).forEach((key) => {
        if (filterObject.hasOwnProperty(key)) {
          updatedFilterObject = {
            ...updatedFilterObject,
            [key]: {
              ...updatedFilterObject[key],
              value: filterObject[key].multiValue
                ? querys[key].split(',')
                : querys[key],
            },
          };
          updatedAppliedFilterObject = {
            ...updatedAppliedFilterObject,
            [key]: querys[key],
          };
        } else if (key != 'page') {
          updatedFilter = {
            ...updatedFilter,
            [key]:
              key == 'ascendingOrder' ? JSON.parse(querys[key]) : querys[key],
          };
        }
      });
      this.setState((prevState) => ({
        ...prevState,
        filterObject: updatedFilterObject,
        appliedFilterObj: updatedAppliedFilterObject,
        filter: updatedFilter,
      }));
      this.getResellerListApi({
        ...updatedAppliedFilterObject,
        ...updatedFilter,
        page: querys.page ? querys.page : 1,
      });
    } else {
      this.getResellerListApi(filter);
    }
  }

  parseSearchParams = (params = {}) => {
    const updatedFilterObject = this.state.filterObject;
    let updatedAppliedFilterObject = this.state.appliedFilterObj;
    let updatedFilter = this.state.filter;

    const querys = queryString.parse(params, { decode: true });
    if (!Object.keys(querys).length) {
      Object.keys(updatedFilterObject).forEach((key) => {
        if (updatedFilterObject[key].multiValue) {
          updatedFilterObject[key].value = [];
        } else {
          updatedFilterObject[key].value = '';
        }
      });
      updatedAppliedFilterObject = {};
      updatedFilter = {
        sortField: 'createDate',
        ascendingOrder: false,
      };
    }
    this.setState((prevState) => ({
      ...prevState,
      filterObject: updatedFilterObject,
      appliedFilterObj: updatedAppliedFilterObject,
      filter: updatedFilter,
    }));
    this.getResellerListApi({
      ...updatedAppliedFilterObject,
      ...updatedFilter,
      page: querys.page ? querys.page : 1,
    });
  };

  componentDidUpdate(prevProps) {
    // debugger
    if (prevProps.location.search != this.props.location.search) {
      this.parseSearchParams(this.props.location.search);
    }
    if (prevProps.location.pathname != this.props.location.pathname) {
      // console.log('pathchanged', this.props.location.pathname)
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleChangePage = (newPage) => {
    const { appliedFilterObj, filter } = this.state;
    this.props.history.push({
      search: `?${queryString.stringify(
        { ...appliedFilterObj, ...filter, page: newPage },
        { encode: true }
      )}`,
    });
  };

  createSortHandler = (sortField) => {
    const { appliedFilterObj, resellerInfo, filter } = this.state;
    this.setState((prevState) => ({
      ...prevState,
      filter: {
        ...prevState.filter,
        ascendingOrder: !prevState.filter.ascendingOrder,
        sortField,
      },
    }));
    if (resellerInfo.totalRecords) {
      this.props.history.push({
        search: `?${queryString.stringify(
          {
            ...appliedFilterObj,
            ...filter,
            page: resellerInfo.page,
            sortField,
            ascendingOrder: !filter.ascendingOrder,
          },
          { encode: true }
        )}`,
      });
    }
  };

  handleFilterApply = () => {
    const { filterObject, filter } = this.state;
    const appliedFilterObj = {};
    Object.keys(filterObject).forEach((key) => {
      if (filterObject[key].value) {
        appliedFilterObj[key] = filterObject[key].value;
      }
    });
    this.setState({
      appliedFilterObj,
      openFilter: false,
    });
    this.props.history.push({
      search: `?${queryString.stringify(
        { ...appliedFilterObj, ...filter },
        { encode: true }
      )}`,
    });
  };

  handleClearFilter = () => {
    const { filter, filterObject } = this.state;
    Object.keys(filterObject).forEach((key) => {
      filterObject[key].value = '';
    });
    this.setState({
      appliedFilterObj: {},
      filterObject,
      openFilter: false,
    });
    this.props.history.push({
      search: `?${queryString.stringify({ ...filter }, { encode: true })}`,
    });
  };

  handleRemoveFilterClick = (key) => {
    const { appliedFilterObj, filter, filterObject } = this.state;
    if (appliedFilterObj.hasOwnProperty(`${key}`)) {
      delete appliedFilterObj[key];
    }
    filterObject[key].value = '';
    // this.getResellerListApi({...appliedFilterObj,...filter});
    this.setState((prevState) => ({
      ...prevState,
      appliedFilterObj,
      filterObject,
    }));
    this.props.history.push({
      search: `?${queryString.stringify(
        { ...appliedFilterObj, ...filter },
        { encode: true }
      )}`,
    });
  };

  handleFilterTextChange = (value, key) => {
    this.setState((prevState) => ({
      filterObject: {
        ...prevState.filterObject,
        [key]: { ...prevState.filterObject[key], value },
      },
    }));
  };

  toggleFilter = (flag) => {
    const { appliedFilterObj, filterObject } = this.state;
    if (flag == true) {
      Object.keys(filterObject).forEach((key) => {
        filterObject[key].value = appliedFilterObj[key]
          ? appliedFilterObj[key]
          : '';
      });
      this.setState({ filterObject, openFilter: true });
    } else {
      this.setState({ openFilter: false });
    }
  };

  handleDeleteResellerClick = (flag, resellerUid = '', success = '') => {
    this.setState({ deleteReseller: flag, deleteUid: resellerUid });
    if (success) {
      const { appliedFilterObj, filter, resellerInfo } = this.state;
      this.parseSearchParams(
        `?${queryString.stringify(
          { ...appliedFilterObj, ...filter, page: resellerInfo.page },
          { encode: true }
        )}`
      );
      // this.props.history.push({
      //     search: `?${queryString.stringify({...appliedFilterObj,...filter}, {encode: false})}`
      // })
    }
  };

  handleAddResellerClick = (flag, success = '') => {
    this.setState({ addReseller: flag });
    if (success) {
      const { appliedFilterObj, resellerInfo } = this.state;
      const filter = {
        sortField: 'createDate',
        ascendingOrder: false,
      };
      this.setState({ filter });
      const querys = queryString.parse(this.props.location.search, {
        decode: true,
      });
      if (Object.keys(querys).length && querys.page != 1) {
        this.props.history.push({
          search: `?${queryString.stringify(
            { ...filter, page: 1 },
            { encode: true }
          )}`,
        });
      } else {
        this.parseSearchParams(
          `?${queryString.stringify({ ...filter }, { encode: true })}`
        );
      }
      // this.getResellerListApi({...appliedFilterObj,...filter});
    }
  };

  handleEditResellerClick = (flag, reseller = '', success = '') => {
    if (flag && reseller) {
      const editResellerInfo = {
        companyName: reseller.companyName,
        firstName: reseller.firstName,
        lastName: reseller.lastName,
        email: reseller.email,
        phone: reseller.phone,
      };
      this.setState({ editResellerInfo, updateUid: reseller.uid });
    } else if (success) {
      const { appliedFilterObj, filter, resellerInfo } = this.state;
      this.setState({ editResellerInfo: null, updateUid: '' });
      this.parseSearchParams(
        `?${queryString.stringify(
          { ...appliedFilterObj, ...filter, page: resellerInfo.page },
          { encode: true }
        )}`
      );
    }
    this.setState({ editReseller: flag });
  };

  render() {
    const { classes } = this.props;
    const {
      loader,
      resellerInfo,
      filterObject,
      appliedFilterObj,
      tableLoader,
      openFilter,
      filter,
      addReseller,
      editReseller,
      deleteReseller,
      deleteUid,
      editResellerInfo,
      updateUid,
    } = this.state;

    return (
      <>
        <section className="resellers-section" data-testid="resellersSection">
          {loader ? (
            <Loader />
          ) : this.state.noResellersFound ? (
            <Container maxWidth={false} className="resellers-container">
              <Grid
                container
                className="no-resellers-found"
                alignItems="center"
                justify="center"
              >
                <Grid item className="text-center">
                  <div className="outer-wrapper">
                    <img
                      src={`${S3_ASSETS_PATH}/images/no-campaigns-found.svg`}
                      alt=""
                    />
                    <h3 className="heading1">Please register a Reseller</h3>
                    <p className="heading1">No Resellers found</p>
                  </div>
                  <RoleGuard feature="resellerList.addReseller">
                    <div className="bottom-blk">
                      <a
                        data-testid="resellersSectionRegisterButton"
                        onClick={() => this.handleAddResellerClick(true)}
                        className="primary-btn"
                      >
                        Register a Reseller
                      </a>
                    </div>
                  </RoleGuard>
                </Grid>
              </Grid>
            </Container>
          ) : (
            <Container maxWidth={false} className="resellers-container">
              <div className={classes.title}>
                {resellerInfo.totalRecords || 0} Resellers
              </div>
              <RoleGuard feature="resellerList.addReseller">
                <Grid container className="heading list-unstyled">
                  {/* <Grid item xs={12}>
                                <TableFilter
                                    filterObject={filterObject}
                                    handleFilterApply={this.handleFilterApply}
                                    handleClearFilter={this.handleClearFilter}
                                    handleFilterTextChange={this.handleFilterTextChange}
                                    col={4}
                                    toggleFilter={this.toggleFilter}
                                    openFilter={openFilter}
                                    appliedFilterObj={appliedFilterObj}
                                />
                            </Grid> */}
                  <div className="button-wrapper">
                    <Button
                      data-testid="resellersSectionAddResellerButton"
                      onClick={() => this.handleAddResellerClick(true)}
                      className={'primary-btn'}
                    >
                      <FontAwesomeIcon icon={faPlus} /> Add new Reseller
                    </Button>
                  </div>
                </Grid>
              </RoleGuard>
              {Object.keys(appliedFilterObj).length ? (
                <FilterRow
                  testid="resellersFilterRow"
                  appliedFilterObj={appliedFilterObj}
                  filterObject={filterObject}
                  handleRemoveFilterClick={this.handleRemoveFilterClick}
                  handleClearFilter={this.handleClearFilter}
                />
              ) : null}
              <Grid container justify="center" spacing={0}>
                <Table
                  headRows={headRows}
                  emptyState="no resellers to view"
                  className="resellers-listing"
                  disableHover
                  loading={tableLoader}
                  tableData={resellerInfo.records.map((record, index) => {
                    return (
                      <ResellerListingRow
                        key={index}
                        data={record}
                        handleDelete={this.handleDeleteResellerClick}
                        handleEdit={this.handleEditResellerClick}
                      />
                    );
                  })}
                  handleChangePage={this.handleChangePage}
                  createSortHandler={this.createSortHandler}
                  filter={filter}
                  pagination={{
                    rowsPerPage: resellerInfo.recordsPerPage,
                    page: resellerInfo.page,
                    totalRecords: resellerInfo.totalRecords,
                  }}
                />
              </Grid>
            </Container>
          )}
          {deleteReseller && (
            <DeleteResellerModal
              deleteId={deleteUid}
              handleModalClick={this.handleDeleteResellerClick}
            />
          )}
          {(addReseller || editReseller) && (
            <AddResellerModal
              open={addReseller || editReseller}
              handleModalClick={
                addReseller
                  ? this.handleAddResellerClick
                  : this.handleEditResellerClick
              }
              modalType={editReseller ? 'edit' : 'add'}
              reseller={editReseller ? editResellerInfo : null}
              updateId={editReseller ? updateUid : ''}
            />
          )}
        </section>
      </>
    );
  }
}

export default withStyles(styles)(withPermission(Resellers));
