import { Box, TopBar, RoleGuard, useHasPermission } from 'portal-commons';
import React, { Fragment, useCallback, useMemo, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { Link, useHistory } from 'react-router-dom';
import { Container, Fade, Grid, Menu, MenuItem } from '@material-ui/core';
import { useOktaAuth } from '@okta/okta-react';

import { S3_ASSETS_PATH } from '../../constants';
import { useAuthContext } from '../../contexts';

const Header = (props) => {
  const { title, note, testId } = props;
  const { role, profile } = useAuthContext();
  const { oktaAuth } = useOktaAuth();
  const history = useHistory();
  const [flag, setFlag] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const active = profile?.status.toLowerCase() === 'active';

  const handleLogout = useCallback(() => {
    localStorage.clear();
    sessionStorage.setItem('logoutSuccess', true);
    oktaAuth.signOut('/login');
  }, [oktaAuth]);

  const menus = useMemo(() => {
    const menuItems = [
      active && useHasPermission('profile.view') ? (
        <TopBar.MenuItem key="profile" onClick={() => history.push('/profile')}>
          View CSP Profile
        </TopBar.MenuItem>
      ) : null,
      <TopBar.MenuItem key="logout" onClick={handleLogout}>
        Logout
      </TopBar.MenuItem>,
    ].filter(Boolean);
    return menuItems;
  }, [role, history, active, handleLogout]);

  const userName = localStorage.getItem('okta-token-storage')
    ? JSON.parse(localStorage.getItem('okta-token-storage')).idToken
      ? JSON.parse(localStorage.getItem('okta-token-storage')).idToken.claims
          .name
      : localStorage.getItem('userInfo')
      ? JSON.parse(localStorage.getItem('userInfo')).name
      : ''
    : '';

  const handleClick = (event) => {
    const anchorEl = event.currentTarget;
    setFlag((prevState) => !prevState);
    setAnchorEl(anchorEl);
  };

  const handleClose = () => {
    setFlag((prevState) => !prevState);
    setAnchorEl(null);
  };

  return isMobile ? (
    <Fragment>
      <header data-testid={testId}>
        <Container maxWidth={false} className="flex-centered">
          <Grid
            container
            spacing={0}
            alignItems="center"
            className="flex-centered"
          >
            {isMobile && (
              <Grid
                item
                lg={2}
                xs={isMobile ? 12 : 2}
                style={isMobile ? { height: '100%' } : {}}
              >
                {isMobile && (
                  <div className="header-logo">
                    <Link to="/" data-testid="headerLogoLink">
                      <img
                        src={`${S3_ASSETS_PATH}/images/logo/sideNavLogo.svg`}
                        alt="logo"
                      />
                    </Link>
                  </div>
                )}
              </Grid>
            )}
            {userName ? (
              <Grid item lg={4} className="user-dropdown">
                <Box
                  aria-controls="fade-menu"
                  onClick={handleClick}
                  data-testid="headerNavMenu"
                  justifyContent="center"
                  alignItems="center"
                  className={`header-nav-menu ${anchorEl ? 'active' : ''}`}
                >
                  <Box
                    margin={{ right: 'xxs' }}
                    justifyContent="center"
                    alignItems="center"
                    flexDirection="row"
                  >
                    <img src={`${S3_ASSETS_PATH}/images/wing.svg`} />
                  </Box>
                  <span>{userName}</span>
                </Box>
                <Menu
                  id="fade-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={flag}
                  onClose={handleClose}
                  TransitionComponent={Fade}
                  className="header-drop-down"
                  // classes={{root : styles.root}}
                >
                  {active ? (
                    <RoleGuard feature="profile.view">
                      <MenuItem
                        className="menu-item"
                        onClick={() => history.push('/profile')}
                        data-testid="headerNavMenuProfileLink"
                      >
                        View CSP Profile
                      </MenuItem>
                    </RoleGuard>
                  ) : null}
                  <MenuItem
                    onClick={handleLogout}
                    data-testid="headerNavMenuLogoutLink"
                  >
                    Logout
                  </MenuItem>
                </Menu>
              </Grid>
            ) : (
              ''
            )}
          </Grid>
        </Container>
      </header>
    </Fragment>
  ) : (
    <TopBar
      authenticated={!!userName}
      portal="csp"
      username={userName}
      title={title}
      subText={note}
      menus={menus}
    />
  );
};

export default Header;
