import {
  Tooltip as MuiTooltip,
  TooltipProps as MuiTooltipProps,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfo } from '@fortawesome/pro-regular-svg-icons';

const useStyles = makeStyles((theme) => ({
  customTooltip: {
    '&.MuiTooltip-tooltip': {
      background: '#0091B3',
      color: '#fff',
      boxShadow: theme.shadows[1],
      fontSize: 12,
      borderRadius: '10px',
      maxWidth: 180,
      fontFamily: 'Roboto',
      fontWeight: 400,
    },
    '&.MuiTooltip-tooltipPlacementLeft': {
      borderBottomRightRadius: 0,
    },
    '&.MuiTooltip-tooltipPlacementRight': {
      borderBottomLeftRadius: 0,
    },
  },
}));

interface TooltipProps extends Omit<MuiTooltipProps, 'children'> {
  heading?: boolean;
  testid?: string;
  keyword?: string;
}

const ToolTip: React.FC<TooltipProps> = ({
  placement = 'right-end',
  heading = false,
  keyword,
  title,
  style,
  ...props
}) => {
  const classes = useStyles();

  return (
    <MuiTooltip
      title={title}
      placement={placement}
      classes={{ tooltip: classes.customTooltip }}
      data-testid={props.testid}
      {...props}
    >
      <FontAwesomeIcon
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore as this actually works but missing in FontAwesomeIcon Type
        alt={title}
        icon={faInfo}
        style={{
          cursor: 'pointer',
          width: '9px',
          right: '-15px',
          position: 'absolute',
          top: `${heading ? '1px' : keyword ? '2px' : '20px'}`,
          ...style,
        }}
        color="#0091B3"
      />
    </MuiTooltip>
  );
};
export default ToolTip;
